import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import store from '@/store/index'
import i18n from '../lang'

dayjs.extend(utc)
dayjs.extend(timezone)

// 默认格式
const defaultFormat = 'YYYY-MM-DD HH:mm'
const defaultFormat2 = 'YYYY-MM-DD HH:mm:ss'
const defaultFormat3 = 'YYYY/MM/DD HH:mm:ss'
// utc转local时间
export function dateLocalFormat(date, format) {
  if (!date) {
    return ''
  }
  const formats = format || defaultFormat
  return dayjs.utc(date).local().format(formats)
}

// utc转local时间
export function dateLocalFormat1(date, format) {
  if (!date) {
    return ''
  }
  const formats = format || defaultFormat3
  return dayjs.utc(date).local().format(formats)
}
// utc转对应时区的时间
export function dateTimeFormat(date, format) {
  if (!date) {
    return ''
  }
  const formats = format || defaultFormat
  return dayjs.utc(date).tz(store.state.app.defaultTimeZone.zone).format(formats)
}

// 转换指定时区的时间
export function dateTimeFormatByZone(date, timezone, format) {
  const formats = format || defaultFormat
  return `${dayjs.utc(date).tz(timezone).format(formats)}`
}

// 时间转成utc时间
export function dateUtcFormat(date) {
  if (!date) {
    return ''
  }
  return dayjs.utc(date).format()
}

// 时间戳转格式
export function dateFormat(date, format) {
  if (!date) {
    return ''
  }
  const formats = format || defaultFormat
  return dayjs(date).format(formats)
}

// 将时间转为用户对应时区的时间然后转成UTC时间再转成标准化时间
export function dateTimeToUtc(date) {
  // return dayjs.tz(date, store.state.app.defaultTimeZone.zone).format()
  return dayjs.tz(date, store.state.app.defaultTimeZone.zone).utc().format(defaultFormat2)
}


/**
* 计算两个日期之间的天数
* @param dateString1  开始日期 yyyy-MM-dd
* @param dateString2  结束日期 yyyy-MM-dd
* @returns {number} 如果日期相同 返回一天 开始日期大于结束日期，返回0
*/
function getDaysBetween(dateString1, dateString2) {
  var startDate = Date.parse(dateString1);
  var endDate = Date.parse(dateString2);
  if (startDate > endDate) {
    return 0;
  }
  if (startDate == endDate) {
    return 1;
  }
  var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
  return days;
}

function dateFormat1(fmt, date) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(),        // 年
    "m+": (date.getMonth() + 1).toString(),     // 月
    "d+": date.getDate().toString(),            // 日
    "H+": date.getHours().toString(),           // 时
    "M+": date.getMinutes().toString(),         // 分
    "S+": date.getSeconds().toString()          // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    }
  }
  return fmt;
}
/**
 * 计算两个日期之间的天数 END
 */

//  返回计算两个日期之间的天数的结果
export function returnDateResults(currentDate) {

  currentDate = dateFormat1("YYYY-mm-dd", currentDate)
  let endDateYear = Number(currentDate.split('-')[0])
  let endDateMon = Number(currentDate.split('-')[1]) + 2
  let endDateDay = Number(currentDate.split('-')[2])
  //如果大于12就是下一年
  if (endDateMon > 12) {
    endDateMon -= 12
    endDateYear++
  }
  let endDateMonMaxDayDate = dateFormat1("YYYY-mm-dd", new Date(endDateYear, endDateMon, 0))
  endDateDay > Number(endDateMonMaxDayDate.split('-')[2]) ? endDateDay = Number(endDateMonMaxDayDate.split('-')[2]) : ''
  endDateMon < 10 ? endDateMon = `0${endDateMon}` : ''
  endDateDay < 10 ? endDateDay = `0${endDateDay}` : ''
  let endDate = `${endDateYear}-${endDateMon}-${endDateDay}`
  return getDaysBetween(currentDate, endDate)
}